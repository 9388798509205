import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Bio from '../components/bio';
import ProjectCard from '../components/projectCard';

const Projects = ({ data }) => {
  return (
    <Layout title="Projects">
      <ProjectCard
        image={data.oare.childImageSharp.fixed}
        title="OARE"
        link="http://oare.byu.edu"
      >
        The Old Assyrian Research Environment is a website I created for BYU for
        researching ancient Assyrian texts. I created it with Vue, TypeScript,
        and Express.
      </ProjectCard>
      <ProjectCard
        image={data.zonetime.childImageSharp.fixed}
        title="Zonetime"
        link="https://zonetime.netlify.app"
      >
        Zonetime is a project that lets you easily sync meeting times across
        timezones.
      </ProjectCard>
      <ProjectCard
        image={data.chinesedb.childImageSharp.fixed}
        title="Chinese Database"
        link="https://chinesedb.netlify.app/"
      >
        Flexibly search for Chinese characters by frequency, definition, and
        more.
      </ProjectCard>
      <Bio />
    </Layout>
  );
};

export default Projects;

export const query = graphql`
  {
    oare: file(relativePath: { eq: "oare-project.png" }) {
      childImageSharp {
        fixed(width: 150, height: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    zonetime: file(relativePath: { eq: "zonetime.png" }) {
      childImageSharp {
        fixed(width: 150, height: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    chinesedb: file(relativePath: { eq: "chinesedb.png" }) {
      childImageSharp {
        fixed(width: 150, height: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
