import React, { ReactElement, ReactNode } from 'react';
import Image, { FixedObject } from 'gatsby-image';
import PropTypes from 'prop-types';

import './styles/projectCard.css';

interface ProjectCardProps {
  children: ReactNode,
  title: string;
  image: FixedObject;
  link: string;
}

const ProjectCard = ({
  children, title, image, link,
}: ProjectCardProps): ReactElement => (
  <a href={link} target="_blank" rel="noreferrer">
    <div
      style={{
        minHeight: 150,
      }}
      className="card-container text-black w-full rounded-md flex overflow-hidden border border-gray-400 border-solid mb-8"
    >
      <div className="hidden md:block">
        <Image fixed={image} />
      </div>
      <div className="p-6">
        <h4 className="font-extrabold text-lg mb-4">{title}</h4>
        {children}
      </div>
    </div>
  </a>
);

ProjectCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default ProjectCard;
